/* eslint-disable no-unused-vars */
import React from 'react'
import Push from  'push.js'

/*
Push.Permission.DEFAULT; // 'default'
Push.Permission.GRANTED; // 'granted'
Push.Permission.DENIED; // 'denied'

Push.Permission.has();

Push.Permission.get();

Push.Permission.request(onGranted, onDenied);

*/

function AddNotification() {
    function showNotification(e) {
        Push.create("Hello world!", {
            body: "How's it hangin'?",
            icon: '/icon.png',
            timeout: 4000,
            onClick: function () {
                window.focus();
                this.close();
            },
            onError: function() {
                console.log('Push.create ERROR')
            }
        })
    }

    function requestPermission() {
        Push.Permission.request(function() {
            console.log('requestPermission', 'granted')
        }, function() {
            console.log('requestPermission', 'denied')
        })
    }

    return <div>
        <button onClick={requestPermission}>Request Permission</button>
        <button onClick={showNotification}>ShowNotificaton</button>
    </div>
}

export default AddNotification