import React from 'react'

import AddNotification from '../components/AddNotification'

function TestPushJs() {
    return (
        <AddNotification></AddNotification>
    )
}

export default TestPushJs